<template>
  <div class=" primaryDiv fill-height d-flex flex-column justify-center">
    <v-fade-transition mode="out-in">
      <div :key="1" v-if="state != 'end-session'">
        <div class="text-center headerStyle">
          <div class="titleContentBox mt-4">
            ابزار پیش مشاوره روانشناسی با هوش مصنوعی
          </div>
          <div class="text-center textContentBox mb-lg-10 mb-3">
            به شما کمک می‌کند تا به راحتی و در هر زمان، مشکلات روحی و روانی خود را
            مطرح کنید و راهکارهای موثری بگیرید
          </div>

        </div>
        <!--    <div class="loaderArea" v-if="loader">-->
        <!--      <v-progress-circular indeterminate></v-progress-circular>-->
        <!--    </div>-->
        <div class="formArea">


          <v-card :loading="loader" class="backgroundContainer pa-0 ">

            <div class="messengerContent  pt-5">
              <div class="getInitMessage mt-5" v-if="state == 'get-init-info'">
                <div class="font-weight-bold">
                  اطلاعات اولیه
                </div>
                <v-form ref="init">
                  <v-row class="mt-2">
                    <v-col cols="12" lg="6">
                      <v-text-field v-model="init_message.name" :rules="requiredRules" outlined
                                    style="border-radius: 12px"
                                    label="نام و نام خانوداگی"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field v-model="init_message.age" :rules="requiredRules" outlined
                                    style="border-radius: 12px"
                                    label="سن"></v-text-field>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col cols="12">

                      <v-textarea v-model="init_message.description" outlined :rules="requiredRules"
                                  style="border-radius: 12px"
                                  label="شرح کوتاه از مشکل"></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
                <div class="text-center">
                  <v-btn :loading="loader" x-large color="primary" elevation="0" @click="sendStartFlowRequest"
                         class="red">
                    شروع فرایند پیش‌مشاوره
                  </v-btn>
                </div>

                <h2 v-if="state == 'session-in-proccess'" class="subtitleContentBox text-center  mb-3"
                    style="!important;text-align: right">
                  با هوش مصنوعی
                </h2>


              </div>
              <div class="messagesArea" id="messagesArea" v-if="state == 'session-in-proccess'">


                <div v-for="(message , i ) in messages" :key="i" class="d-flex mb-5"
                     :class="message.sender == 'chatgpt' ? 'justify-start' : 'justify-end' ">
                  <div class="messageItem chatGptSender " v-if="message.sender == 'chatgpt'">
                    {{ loadQuestion(message.message) }}
                  </div>
                  <div class="messageItem clientSender" :key="i" v-if="message.sender == 'client'">
                    {{ message.message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="actionArea">
              <v-form ref="answer" @submit.prevent="answerQuestion">
                <v-text-field v-model="answer" label="پاسخ" outlined class="textFieldStyle mt-4"
                              v-if="state == 'session-in-proccess'">

                </v-text-field>
                <div class="text-center">

                  <v-btn :loading="loader" v-if="state == 'session-in-proccess'" large color="primary"
                         style="margin-top: -2px" elevation="0" :disabled="!answer.length || loader "
                         @click="answerQuestion">
                    ارسال
                  </v-btn>

                </div>
              </v-form>
            </div>
          </v-card>
        </div>
      </div>
      <div :key="2" v-else>
        <div class="stepperContext">
          <div class="introSlide mt-5">
            <img src="../../assets/static_presession/desktop-login-vector.c9aa7014fc15cfbf07a5.png" alt="">
            <div class="sliderContent">
              <div class="titleSlider">
                اطلاعات شما ثبت شد
              </div>
              <div class="contentSlider">
                پشتیبان‌های ما در سریع‌ترین زمان ممکن مناسب ترین تراپیست را برای شما انتخاب می‌کنند و برای شروع فرایند
                جلسات با شما ارتباط برقرار می‌کنند
              </div>
              <router-link :to="`/fa/panel/event/${event_id}`" v-if="event_id">
                <div class="buttonSlier " @click="e1++">
                  دکتر‌های پیشنهادی
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import axios from "axios";

export default {

  mounted() {
    // this.state = 'session-in-proccess'
    // this.sendStartFlowRequest()

  },

  data() {
    return {
      state: 'get-init-info',
      init_message: {

        name: "علیرضا",
        age: "۲۵",
        description: "در طول همش خوابم میاد و نا امید ام و هدف خاصی ام ندارم. ",
      },
      messages: [],
      thread_id: null,
      messagesCount: 0,
      answer: "",
      loader: false,
      event_id: 15,
    }
  },
  methods: {
    scrollToDown() {
      var messagesArea = document.getElementById('messagesArea');
      messagesArea.scrollTop = messagesArea.scrollHeight;
    },
    loadQuestion(message) {
      return JSON.parse(message).question
    },
    sendStartFlowRequest() {
      if (this.$refs.init.validate()) {
        this.loader = true
        let message = 'سلام '
        message += this.init_message.name + ' هستم. '
        message += this.init_message.age + 'ساله هستم.'
        message += this.init_message.description


        this.requesting('user', 'startPreSessionAiFlow', {}, {
          flow_id: 1,
          init_message: message,
        }).then((resp) => {
          console.log(resp, '121212')
          this.state = 'session-in-proccess'
          this.thread_id = resp.data.thread.id
          this.event_id = resp.data.event.id
          console.log(this.event_id, '121212')
          setTimeout(() => {
            this.getListMessages()
          }, 3000)
        }).finally(() => {


        })
      }
    },
    getListMessages() {
      this.loader = true
      axios.get(`https://psychepro.simiaroom.com/api/multi-assistant/${this.thread_id}/get-thread-messages`).then((resp) => {
        this.messagesCount = this.messages.length
        let newMessages = resp.data.reverse()
        let lastIndex = resp.data.length - 1
        if (resp.data.length == 0 || resp.data[lastIndex].sender == 'client') {
          this.messages = newMessages
          setTimeout(() => {
            this.getListMessages()
          }, 3000)
        } else {
          this.messages = newMessages
          this.checkConversationEndOrNot()
          this.loader = false
          setTimeout(() => {
            this.scrollToDown()
          }, 500)
        }
      }).finally(() => {

      })
    },
    checkConversationEndOrNot() {
      this.messages.forEach((item) => {
        if (item.sender == 'chatgpt') {
          let message = JSON.parse(item.message)
          console.log(message)
          // eslint-disable-next-line no-prototype-builtins
          if (message.hasOwnProperty('end')) {
            this.state = 'end-session'
          }
        }
      })
    },
    answerQuestion() {
      if (!this.loader) {
        if (this.$refs.answer.validate()) {
          this.loader = true
          axios.post('https://psychepro.simiaroom.com/api/multi-assistant/answer-question', {
            thread_id: this.thread_id,
            message: this.answer,
            event_id: this.event_id
          }).then(() => {
            this.scrollToDown()
            this.getListMessages()
            this.answer = ""
          })
        }
      }
    }
  }
}
</script>

<style scoped>


@media (min-width: 200px) and (max-width: 600px) {
  .textFieldStyle {
    width: 90% !important;
  }

  .messengerContent {
    min-height: 350px !important;
  }

  .backgroundContainer {

    min-height: 500px !important;
    padding: 20px;
  }

  .formArea {
    width: 100% !important;

    padding: 20px;
  }

  .headerStyle {
    margin-top: 20px !important;
  }

  .stepperContext {
    width: 95% !important;
  }

  .introSlide img {
    width: 80% !important;
  }
  .messagesArea {
    height: 300px !important;
  }

}

.messagesArea {
  height: 500px;
  overflow-y: auto;
}

.headerContext {
  direction: rtl !important;
  justify-content: space-between;
}


.headerStyle {
  margin-top: -100px;
}

.primaryDiv {
  background-color: white !important;
}


.textFieldStyle {
  width: 70%;
  margin: 0 auto;
  border-radius: 10px;
}


.formArea {
  width: 50%;
  overflow-y: auto;
  margin: 0px auto;
  background: white;
  border-radius: 20px;
  padding: 20px;
}

.getInitMessage {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-radius: 8px;
}


.chatGptSender {

  background-color: #78a5ff;
  border-radius: 10px 0px 10px 10px;
  color: white;
  padding: 10px;
  line-height: 25pt;
}

.clientSender {
  background-color: #d1dcff;

  border-radius: 0px 10px 10px 10px;
  color: #574596;
  padding: 10px;
  line-height: 25pt;

}

.messengerContent {
  min-height: 500px;
  padding: 10px;
  overflow-y: auto;
}

.actionArea {
  padding: 10px;
}

.backgroundContainer {

  box-shadow: 0 40px 40px -40px rgba(0, 0, 0, .2), inset 0 10px 50px -30px rgba(0, 0, 0, .1) !important;
  /*box-shadow: 0 30px 50px -20px rgba(0,0,0,.2);*/
  min-height: 500px;
  padding: 20px;
  border-radius: 6px;
  background: linear-gradient(90deg, white 21px, transparent 0%) center,
  linear-gradient(#ffffff 21px, transparent 0%) center,
  #e2d9f7;
  background-size: 23px 23px;
}


.headerContext {
  direction: rtl !important;
  justify-content: space-between;
}

.buttonSlier {
  background: #1cba88;
  padding: 10px;
  font-family: bold !important;
  color: white;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 50px;
  text-align: center;
}

.sliderContent {
  width: 90%;
  margin: 0px auto;
}


.introSlide img {
  width: 100%;
}

.introSlide {
  text-align: center;
  direction: rtl;
}

.titleSlider {
  font-size: 15pt;
  font-family: bold !important;
  color: rgb(61 86 86/1);
  margin-bottom: 10px;
}

.contentSlider {
  font-size: 11pt;
  color: rgb(61 86 86/1);
}

.stepperContext {
  width: 500px;
  margin: 0px auto;
}


</style>
